import React from "react"

// Components
import Seo from "components/seo"

// Page sections
import IndividualCaseStudy from "../../screens/resources/case-study/slideshow"

const CaseStudy = () => {
    return (
    <React.Fragment>
      <IndividualCaseStudy
        title="Case Study #4: Tina"
        src="https://media.visbymedical.com/case-study/sti-female-patient-tina/story.html"
        bodytxt="A case of undertreatment associated with syndromic management of STIs."
      />
    </React.Fragment>
  )
}
export default CaseStudy

export const Head = () => (
  <Seo
        title="Patient Case Study 4: Undertreatment of STIs"
        description="Tina is a recently married 27-year-old woman who has dreams of having large family. She presents to the local clinic with complaints of vaginal discharge and irritation."
        image="/meta/case-study-tina.jpg"
  />
)
